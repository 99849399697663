import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage } from "../../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";

const HomePage = () => {
    const userType = sessionStorage.getItem('userType');
    const myPermission = sessionStorage.getItem('permissions');
    let permissions = Array.isArray(JSON.parse(myPermission)) ? JSON.parse(myPermission)?.map(x => x.value) : [];
    const [totalUser, setTotalUser] = useState("");
    const [totalVerified, setTotalVerified] = useState("");
    const [totalPending, setTotalPending] = useState("");
    const [appVersion, setAppVersion] = useState("");


    const totaluserData = async () => {
        await AuthService.getTotaluser().then(async result => {
            if (result) {
                try {
                    setTotalUser(result.data);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                alertErrorMessage(result);
            }
        })
    }

    const totalVerifiedUser = async () => {
        await AuthService.getTotalVerified().then(async result => {
            if (result) {
                try {
                    setTotalVerified(result.data);
                } catch (error) {

                    alertErrorMessage(error);
                }
            } else {
                alertErrorMessage(result);
            }
        })
    }

    const totalPendingUser = async () => {
        await AuthService.getTotalPending().then(async result => {
            if (result) {
                try {
                    setTotalPending(result.data);
                } catch (error) {
                    alertErrorMessage(error)
                }
            } else {
                alertErrorMessage("Fail TotalPanding");
            }
        })
    }

    const updateAppVersion = async () => {
        if (!appVersion) {
            alertErrorMessage("Please enter version")
            return
        }
        LoaderHelper.loaderStatus(true)
        try {
            const result = await AuthService.addVersion(appVersion)
            LoaderHelper.loaderStatus(false)
            if (result?.success) {
                alertSuccessMessage('Version Updated')
                setAppVersion("")
            } else {
                alertErrorMessage(result?.message);
            }
        } catch (error) {
            LoaderHelper.loaderStatus(false)
            alertErrorMessage(error?.message)
        }
    };


    useEffect(() => {
        totaluserData();
        totalVerifiedUser();
        totalPendingUser();
    }, []);


    return (

        <>
            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="fa fa-th" ></i></div>
                                            Dashboard
                                        </h1>
                                    </div>
                                    {/* {userType === '1' &&
                                        <div className="col-auto mt-4">
                                            <input className="form-control  form-control-solid" type="text" placeholder=" App Version" name="bannerFile" onChange={(e) => setAppVersion(e.target.value)} value={appVersion} />
                                            <button className="btn btn-indigo   btn-block w-100 mt-2" type="button" onClick={updateAppVersion}> Update App Version </button>
                                        </div>} */}
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="container-xl px-4 mt-n10">
                        <div className="row">

                            {permissions.includes(1) || userType === '1' ?
                                <div className="col-lg-6 col-xl-4 mb-4">
                                    <div className="card bg-primary text-white h-100">
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="me-3">
                                                    <div className="text-white-75">Total Users</div>
                                                    <div className="display-4 fw-bold">{totalUser}</div>
                                                </div>
                                                <i className="feather-xl text-white-50 fa fa-user-friends"></i>
                                            </div>
                                        </div>
                                        <div className="card-footer d-flex align-items-center justify-content-between small">
                                            <Link className="text-white stretched-link" to="/dashboard/tradelist" style={{ cursor: 'pointer' }} >View All</Link>
                                            <div className="text-white"><i className="fas fa-angle-right"></i></div>
                                        </div>
                                    </div>
                                </div>
                                : null
                            }

                            {permissions.includes(2) || userType === '1' ?
                                <>
                                    <div className="col-lg-6 col-xl-4 mb-4">
                                        <div className="card bg-success text-white h-100">
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="me-3">
                                                        <div className="text-white-75">Total Verified Users</div>
                                                        <div className="display-4 fw-bold">{totalVerified}</div>
                                                    </div>
                                                    <i className="feather-xl text-white-50 fa fa-user-check"></i>
                                                </div>
                                            </div>
                                            <div className="card-footer d-flex align-items-center justify-content-between small">
                                                <Link to="/dashboard/approvedkyc" className="text-white stretched-link" style={{ cursor: 'pointer' }} >View All</Link>
                                                <div className="text-white"><i className="fas fa-angle-right"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-4 mb-4">
                                        <div className="card bg-danger text-white h-100">
                                            <div className="card-body">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="me-3">
                                                        <div className="text-white-75 ">Total Pending Kyc's</div>
                                                        <div className="display-4 fw-bold">{totalPending}</div>
                                                    </div>
                                                    <i className="feather-xl text-white-50 fa fa-user-slash "></i>
                                                </div>
                                            </div>
                                            <div className="card-footer d-flex align-items-center justify-content-between small">
                                                <Link className="text-white stretched-link" to="/dashboard/pendingkyc" style={{ cursor: 'pointer' }}>View All</Link>
                                                <div className="text-white"><i className="fa fa-angle-right"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                : null
                            }

                        </div>
                    </div>
                </main>
            </div >
        </>
    );
};
export default HomePage;